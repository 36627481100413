var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"mt-0 bg-secondary-o-50 mb-3 rounded-lg",on:{"click":_vm.togglePanel}},[_c('v-expansion-panel-header',{staticClass:"ps-0"},[_c('span',{staticClass:"font-weight-black font-size-lg ms-5"},[_vm._v(" "+_vm._s(_vm.warehouseDetails.text)+" ")])]),_c('v-expansion-panel-content',{staticClass:"ps-0"},[_c('div',{staticClass:"ms-5 border-top"},[(!_vm.serverDataLoaded)?_c('div',{staticClass:"d-flex justify-content-center mt-5"},[_c('v-progress-circular',{attrs:{"size":50,"width":5,"color":"#7e04b7","indeterminate":""}})],1):(_vm.serverDataLoaded)?_c('form',{staticClass:"form-body mt-5"},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('v-checkbox',{attrs:{"dense":"","color":"#7e04b7"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mt-2 font-size-md font-weight-medium"},[_vm._v(" Scan each unit when putaway ")])]},proxy:true}]),model:{value:(_vm.formData.scanEachUnitEnabled),callback:function ($$v) {_vm.$set(_vm.formData, "scanEachUnitEnabled", $$v)},expression:"formData.scanEachUnitEnabled"}})],1),(_vm.serverData)?_c('v-col',{staticClass:"mt-2",attrs:{"md":"6"}},[(_vm.formData.scanEachUnitEnabled)?_c('v-row',{staticClass:"ms-3"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Choose Rule","dense":"","outlined":"","rounded":"","item-text":"text","item-value":"index","items":_vm.serverData.apply_rule_types,"error-messages":_vm.scanEachEunitRuleErrors},on:{"input":function($event){return _vm.$v.formData.scanEachUnitRules.$touch()},"blur":function($event){return _vm.$v.formData.scanEachUnitRules.$touch()}},model:{value:(_vm.formData.scanEachUnitRules),callback:function ($$v) {_vm.$set(_vm.formData, "scanEachUnitRules", $$v)},expression:"formData.scanEachUnitRules"}})],1),(
                  _vm.formData.scanEachUnitRules !== 'all' &&
                  _vm.formData.scanEachUnitRules !== null
                )?_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Choose Customer(s)","multiple":"","dense":"","outlined":"","item-text":"text","item-value":"index","rounded":"","items":_vm.serverData.clients,"error-messages":_vm.scanEachUnitCustomersErrors},on:{"input":function($event){return _vm.$v.formData.scanEachUnitCustomers.$touch()},"blur":function($event){return _vm.$v.formData.scanEachUnitCustomers.$touch()}},model:{value:(_vm.formData.scanEachUnitCustomers),callback:function ($$v) {_vm.$set(_vm.formData, "scanEachUnitCustomers", $$v)},expression:"formData.scanEachUnitCustomers"}})],1):_vm._e()],1):_vm._e()],1):_vm._e()],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"md":"3"}},[_c('v-checkbox',{attrs:{"dense":"","color":"#7e04b7"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mt-2 font-size-md font-weight-medium"},[_vm._v(" Allow Putaway Across Zones ")])]},proxy:true}]),model:{value:(_vm.formData.allowPutawayArossZonesEnabled),callback:function ($$v) {_vm.$set(_vm.formData, "allowPutawayArossZonesEnabled", $$v)},expression:"formData.allowPutawayArossZonesEnabled"}})],1),(_vm.serverData)?_c('v-col',{staticClass:"mt-2",attrs:{"md":"6"}},[(_vm.formData.allowPutawayArossZonesEnabled)?_c('v-row',{staticClass:"ms-3"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Choose Rule","dense":"","outlined":"","rounded":"","item-text":"text","item-value":"index","items":_vm.serverData.apply_rule_types,"error-messages":_vm.allowPutawayAcroosZonesRuleErrors},on:{"input":function($event){return _vm.$v.formData.allowPutawayArossZonesRules.$touch()},"blur":function($event){return _vm.$v.formData.allowPutawayArossZonesRules.$touch()}},model:{value:(_vm.formData.allowPutawayArossZonesRules),callback:function ($$v) {_vm.$set(_vm.formData, "allowPutawayArossZonesRules", $$v)},expression:"formData.allowPutawayArossZonesRules"}})],1),(
                  _vm.formData.allowPutawayArossZonesRules !== 'all' &&
                  _vm.formData.allowPutawayArossZonesRules !== null
                )?_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Choose Customer(s)","multiple":"","dense":"","outlined":"","item-text":"text","item-value":"index","rounded":"","items":_vm.serverData.clients,"error-messages":_vm.allowPutawayAcroosZonesCustomersSelectErrors},on:{"input":function($event){return _vm.$v.formData.allowPutawayArossZonesCustomers.$touch()},"blur":function($event){return _vm.$v.formData.allowPutawayArossZonesCustomers.$touch()}},model:{value:(_vm.formData.allowPutawayArossZonesCustomers),callback:function ($$v) {_vm.$set(_vm.formData, "allowPutawayArossZonesCustomers", $$v)},expression:"formData.allowPutawayArossZonesCustomers"}})],1):_vm._e()],1):_vm._e()],1):_vm._e()],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"md":"3"}},[_c('v-checkbox',{attrs:{"dense":"","color":"#7e04b7"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"mt-2 font-size-md font-weight-medium"},[_vm._v(" Allow to split Putaway Task ")])]},proxy:true}]),model:{value:(_vm.formData.allowToSplitPutawayTaskEnabled),callback:function ($$v) {_vm.$set(_vm.formData, "allowToSplitPutawayTaskEnabled", $$v)},expression:"formData.allowToSplitPutawayTaskEnabled"}})],1),(_vm.serverData)?_c('v-col',{staticClass:"mt-2",attrs:{"md":"6"}},[(_vm.formData.allowToSplitPutawayTaskEnabled)?_c('v-row',{staticClass:"ms-3"},[_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Choose Rule","dense":"","outlined":"","rounded":"","item-text":"text","item-value":"index","items":_vm.serverData.apply_rule_types,"error-messages":_vm.allotToSplitPutawayTaskRuleErrors},on:{"input":function($event){return _vm.$v.formData.allowToSplitPutawayTaskRules.$touch()},"blur":function($event){return _vm.$v.formData.allowToSplitPutawayTaskRules.$touch()}},model:{value:(_vm.formData.allowToSplitPutawayTaskRules),callback:function ($$v) {_vm.$set(_vm.formData, "allowToSplitPutawayTaskRules", $$v)},expression:"formData.allowToSplitPutawayTaskRules"}})],1),(
                  _vm.formData.allowToSplitPutawayTaskRules !== 'all' &&
                  _vm.formData.allowToSplitPutawayTaskRules !== null
                )?_c('v-col',{attrs:{"md":"6"}},[_c('v-select',{attrs:{"label":"Choose Customer(s)","multiple":"","dense":"","outlined":"","item-text":"text","item-value":"index","rounded":"","items":_vm.serverData.clients,"error-messages":_vm.allotToSplitPutawayTaskCustomersSelectErrors},on:{"input":function($event){return _vm.$v.formData.allowToSplitPutawayTaskCustomers.$touch()},"blur":function($event){return _vm.$v.formData.allowToSplitPutawayTaskCustomers.$touch()}},model:{value:(_vm.formData.allowToSplitPutawayTaskCustomers),callback:function ($$v) {_vm.$set(_vm.formData, "allowToSplitPutawayTaskCustomers", $$v)},expression:"formData.allowToSplitPutawayTaskCustomers"}})],1):_vm._e()],1):_vm._e()],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"label":"Default Missing location","dense":"","outlined":"","rounded":"","clearable":"","item-text":"name","item-value":"id","items":_vm.serverData.locations,"loading":_vm.warehouseLoading,"search-input":_vm.warehouseSearch,"error-messages":_vm.stagingLocationError},on:{"update:searchInput":function($event){_vm.warehouseSearch=$event},"update:search-input":function($event){_vm.warehouseSearch=$event},"keydown":_vm.searchWarehouseLocation},model:{value:(_vm.defaultStagingLocation),callback:function ($$v) {_vm.defaultStagingLocation=$$v},expression:"defaultStagingLocation"}})],1)],1),_c('v-row',{staticClass:"justify-center mb-3"},[_c('v-col',{staticClass:"justify-content-end",attrs:{"md":"2"}},[_c('button',{staticClass:"btn btn-info px-5 py-3 ls1",on:{"click":function($event){$event.preventDefault();return _vm.submitConfiguration.apply(null, arguments)}}},[_vm._v(" Submit ")])])],1)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }