<template>
  <ConfigurationSettings />
</template>

<script>
import ConfigurationSettings from "@/own/components/warehouseManagement/mainConfiguration/ConfigurationSettings";
export default {
  components: { ConfigurationSettings },
  name: "MainConfiguration",
  data() {
    return {
      items: ["Receiving", "Putaway"],
    };
  },
};
</script>

<style scoped>
.custom-container-height {
  overflow-y: scroll;
  position: relative;
  height: calc(100vh - 171px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}
</style>
